import { useLocation, useNavigate } from "react-router-dom";
import ToolPrice from "../LandingPage/ToolPrice";
import cup from "../../assets/svgs/cup.svg";

export default function ComparisonCard() {
  const { state } = useLocation();
  const { toolData, element, category } = state;
  const handleNavigate = useNavigate();

  console.log(category);

  const getShortenedDescription = (description) => {
    return description.length > 100
      ? description.substring(0, 100) + " ..."
      : description;
  };

  const getShortenedUrl = (url) => {
    return url.length > 30 ? url.substring(0, 30) + " ..." : url;
  };
  return (
    <div className="grid grid-rows md:grid-cols-12 gap-10 text-[#814627]">
      <div className="md:col-span-6 comparisonCard rounded-xl py-10 px-5 flex flex-col ">
        <div className="flex flex-row gap-x-10">
          <div className="w-10 h-10 md:w-10 bg-white rounded-lg">
            <img
              className="w-full p-1 "
              src={toolData.iconUrl}
              alt={toolData.iconUrl}
            />
          </div>
          <div className="text-xl md:text-3xl">
            <p>{toolData.ToolName}</p>
          </div>
        </div>
        <img
          src={toolData.imageUrl}
          alt={toolData.imageUrl}
          className="mt-5 md:mt-16 rounded-lg  border-[9px] border-[#8B433A80] shadowCard "
        />
        <p className="mt-16 md:mt-12 text-[#3D4754] text-lg md:text-xl text-left">
          {getShortenedDescription(toolData.Description)}
        </p>
        <div className="flex flex-col gap-y-5 mt-12">
          {toolData.Keywords.map((keyword) => {
            return (
              <div className="bg-[#FFCCB9] px-4 py-1 rounded-lg w-fit text-sm md:text-lg">
                <p>{keyword}</p>
              </div>
            );
          })}
        </div>
        <ToolPrice
          priceData={toolData.Prices}
          classNameMain={
            "mx-auto mt-12 pb-5 w-full grid md:grid-cols-2 gap-2 md:gap-10 mt-5 md:mt-10"
          }
          classNameElement={
            "bg-[#FFDBCE] text-[#5E321A] text-sm md:text-lg font-normal py-10 rounded-2xl text-center"
          }
        />
        <div className="hidden md:flex h-[15rem]">
          <div className="comparisonRankingCard h-auto px-10 py-5 mt-2 md:mt-12">
            {toolData.Category.map((currEle, index) => {
              return (
                <div className="flex flex-row gap-x-10 text-[#3D4754] font-normal text-xl gap-y-9">
                  <div className="col-span-2 w-10 relative">
                    <img src={cup} alt="rankImage" />
                    <span className="absolute top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-[#5E321A] text-xs font-semibold">
                      {currEle.rank}
                    </span>
                  </div>
                  <p>
                    Ranking in{" "}
                    <span className="underline">{currEle.category}</span>
                  </p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex flex-col gap-y-[10px] mt-2 md:mt-12">
          <div className="bg-[#FFCBBA] rounded-xl w-full cursor-pointer">
            <p
              className="px-20 py-10 text-[#814627] text-xs md:text-xl font-semibold"
              onClick={() =>
                handleNavigate(`/tool/${toolData.slug}`, {
                  state: { selectedCategory: category },
                })
              }
            >
              More About {toolData.ToolName}
            </p>
          </div>
          <div className="bg-[#FF6653BF] mx-auto w-8/12 rounded-xl text-[10px] font-semibold text-white tracking-wide px-5 py-3">
            <a href={toolData.webLink} target="blank">
              <p>{getShortenedUrl(toolData.webLink)}</p>
            </a>
          </div>
        </div>
      </div>
      <div className="md:col-span-6 comparisonCard rounded-xl py-10 px-5 flex flex-col">
        <div className="flex flex-row gap-x-10">
          <div className="w-10 h-10 md:w-10 bg-white rounded-lg">
            <img
              className="w-full p-1"
              src={element.iconUrl}
              alt={element.iconUrl}
            />
          </div>
          <div className="text-xl md:text-3xl">
            <p>{element.ToolName}</p>
          </div>
        </div>
        <img
          src={element.imageUrl}
          alt={element.imageUrl}
          className="mt-5 md:mt-16 rounded-lg border-[9px] border-[#8B433A80] shadowCard"
        />
        <p className="mt-16 md:mt-12 text-[#3D4754] text-lg md:text-xl text-left">
          {getShortenedDescription(element.Description)}
        </p>
        <div className="flex flex-col gap-y-5 mt-12">
          {element.Keywords.map((keyword) => {
            return (
              <div className="bg-[#FFCCB9] px-4 py-1 rounded-lg w-fit text-sm md:text-lg">
                <p>{keyword}</p>
              </div>
            );
          })}
        </div>
        <ToolPrice
          priceData={element.Prices}
          classNameMain={
            "mx-auto mt-12 pb-5 w-full grid md:grid-cols-2 gap-2 md:gap-10 mt-5 md:mt-10"
          }
          classNameElement={
            "bg-[#FFDBCE] text-[#5E321A] text-sm md:text-lg font-normal py-10 rounded-2xl text-center"
          }
        />
        <div className="hidden md:flex h-[15rem]">
          <div className="comparisonRankingCard h-auto px-10 py-5 mt-2 md:mt-12">
            {element.Category.map((currEle, index) => {
              return (
                <div className="flex flex-row gap-x-10 text-[#3D4754] font-normal text-xl gap-y-9">
                  <div className="col-span-2 w-10 relative">
                    <img src={cup} alt="rankImage" />
                    <span className="absolute top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-[#5E321A] text-xs font-semibold">
                      {currEle.rank}
                    </span>
                  </div>
                  <p>
                    Ranking in{" "}
                    <span className="underline">{currEle.category}</span>
                  </p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex flex-col gap-y-[10px] mt-2 md:mt-12">
          <div className="bg-[#FFCBBA] rounded-xl w-full cursor-pointer">
            <p
              className="px-20 py-10 text-[#814627] text-xs md:text-xl font-semibold"
              onClick={() =>
                handleNavigate(`/tool/${element.slug}`, {
                  state: { selectedCategory: category },
                })
              }
            >
              More About {element.ToolName}
            </p>
          </div>
          <div className="bg-[#FF6653BF] mx-auto w-8/12 rounded-xl text-[10px] font-semibold text-white tracking-wide px-5 py-3">
            <a href={element.webLink} target="blank">
              <p>{getShortenedUrl(element.webLink)}</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
