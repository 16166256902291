const options = [
  { value: "--Select a Category--", label: "-- Select a Category -- " },
  { value: "Productivity", label: "Productivity" },
  { value: "Search Engine", label: "Search Enigine" },
  { value: "Life Assistant", label: "Life Assistant" },
  { value: "General Writing", label: "General Writing" },
  { value: "AI Chatbots", label: "AI Chatbots" },
  { value: "Summarizer", label: "Summarizer" },
  { value: "Social Media Assistant", label: "Social Media Assistant" },
  { value: "Image Generator", label: "Image Generator" },
  { value: "Design Assistant", label: "Design Assistant" },
  { value: "Video Generator", label: "Video Generator" },
  { value: "Image Editing", label: "Image Editing" },
  { value: "AI Detector", label: "AI Detector" },
  { value: "Prompts", label: "Prompts" },
  { value: "Research", label: "Research" },
  { value: "Developer Tools", label: "Developer Tools" },
  { value: "Low Code-No code", label: "Low Code-No code" },
  { value: "Education Assistant", label: "Education Assistant" },
  { value: "Avatars", label: "Avatars" },
];

export { options };
