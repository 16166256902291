import { useNavigate } from "react-router-dom";
import Container from "../Container";
import logo from "../../assets/svgs/tryRabbitIcon.svg";
import { useState } from "react";
import Hamburger from "hamburger-react";
import UserButton from "./UserButton";
import { isAuth } from "../../helpers/authHelper";

export default function Navbar({ user, setRender, setUser }) {
  const handleNavigate = useNavigate();
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const navOptions = [
    {
      text: "Ranking",
      path: "/ranking",
    },
    {
      text: "Categories",
      path: "/categories",
    },
    {
      text: "Submit",
      path: "/submit",
    },
    {
      text: "Favourites",
      path: "/favourites",
    },
  ];

  return (
    <>
      <Container>
        <div className="flex gap-x-10 h-30 mb-10 md:mb-0 py-5 nav-text font-normal justify-between text-xl">
          <div
            className="w-full md:w-2/12 flex flex-row gap-2 md:justify-center align-middle cursor-pointer"
            onClick={() => handleNavigate("/")}
          >
            <img className="" src={logo} alt="tryrabbittlogo" />
            <p className="text-[#7D4E35] text-3xl font-bold mt-5">Rabbitt</p>
          </div>
          <div className="hidden md:flex gap-x-10">
            {navOptions.map((currEle, index) => {
              return (
                <div
                  key={index}
                  onClick={() => handleNavigate(currEle.path)}
                  className="flex items-center cursor-pointer"
                >
                  {currEle.text === "Favourites" && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                      className="mr-1"
                    >
                      <path
                        d="M13.12 21.31C12.78 21.43 12.22 21.43 11.88 21.31C8.98 20.32 2.5 16.19 2.5 9.19001C2.5 6.10001 4.99 3.60001 8.06 3.60001C9.88 3.60001 11.49 4.48001 12.5 5.84001C13.51 4.48001 15.13 3.60001 16.94 3.60001C20.01 3.60001 22.5 6.10001 22.5 9.19001C22.5 16.19 16.02 20.32 13.12 21.31Z"
                        stroke="#3D4754"
                        strokeWidth="1.75"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                  {currEle.text}
                </div>
              );
            })}
          </div>
          <div className="flex items-center space-x-4 flex-row-reverse">
            {isAuth() ? (
              <div className="hidden md:flex">
                <UserButton user={user} setRender={setRender} setUser={setUser} />
              </div>
            ) : (
              <div className="cursor-pointer hidden md:flex justify-center justify-items-center align-middle">
                <p
                  className="rounded-2xl button-color text-[#FFF6F2] px-5 py-1"
                  onClick={() => handleNavigate("/login")}
                >
                  Login
                </p>
              </div>
            )}
            <div className="bg-[#FFCFC2] rounded-lg md:hidden">
              <Hamburger
                color="#3D4754"
                size={25}
                toggled={isSmallScreen}
                toggle={setIsSmallScreen}
              />
            </div>
          </div>
        </div>
      </Container>
      {isSmallScreen && (
        <div
          className="fixed top-0 left-0 z-50 w-screen h-screen bg-[#FFDBCE] flex flex-col items-center justify-center nav-text font-normal text-sm"
          onClick={() => setIsSmallScreen(false)}
        >
          <div className="absolute top-6 left-6 h-10 w-10 flex items-center justify-center rounded-lg">
            <img className="" src={logo} alt="tryrabbittlogo" />
          </div>
          <div
            className="absolute top-6 right-6 h-10 w-10 flex items-center justify-center rounded-lg"
            onClick={() => setIsSmallScreen(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              className="w-10 h-10"
            >
              <rect
                x="24"
                y="24"
                width="24"
                height="24"
                rx="12"
                transform="rotate(-180 24 24)"
                fill="#FFAF99"
              />
              <path
                d="M8.03357 8.03357L15.84 15.84"
                stroke="#3D4754"
                stroke-width="1.2"
                stroke-linecap="round"
              />
              <path
                d="M15.84 8.03357L8.03351 15.84"
                stroke="#3D4754"
                stroke-width="1.2"
                stroke-linecap="round"
              />
            </svg>
          </div>
          <div className="w-6/12 flex flex-col items-center bg-[#FFDBCE]">
            {navOptions.map((currElem, index) => (
              <div className="w-full">
                <p
                  key={index}
                  className="text-[18px] font-semibold cursor-pointer hover:text-[rgba(255, 142, 111, 0.75)] transition-all ease-out mb-6"
                  onClick={() => handleNavigate(currElem.path)}
                >
                  {currElem.text}
                </p>
                <hr className="w-full my-6 border border-gray-600"></hr>
              </div>
            ))}
            {/* <p
              className="rounded-full px-8 py-2 transition-all ease-out button-color text-[18px] text-white"
              onClick={() => handleNavigate("/login")}
            >
              Login
            </p> */}
            {isAuth() ? (
              <UserButton user={user} setRender={setRender} setUser={setUser} />
            ) : (
              <div className="cursor-pointer md:hidden justify-center justify-items-center align-middle">
                <p
                  className="rounded-2xl button-color text-[#FFF6F2] px-5 py-1"
                  onClick={() => handleNavigate("/login")}
                >
                  Login
                </p>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
