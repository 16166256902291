import toast from "react-hot-toast";
import {
  addFavourites,
  deleteFavourites,
} from "../../services/favourite.service";
import { isAuth } from "../../helpers/authHelper";
import heart from "../../assets/svgs/heart.svg";
import heartFilled from "../../assets/svgs/heartFilled.svg";
import { useState } from "react";
import Loading from "../Loading";

export default function ToolsHeader({
  toolData,
  favorites,
  setIsFavoriteClicked,
  isFavoriteClicked,
}) {
  const { token } = isAuth();
  const [isLoading, setIsLoading] = useState(false);

  const handleFavoriteClick = async (element) => {
    const isFavorite =
      favorites && favorites.some((favorite) => favorite._id === element._id);
    if (token) {
      try {
        setIsLoading(true);
        if (isFavorite) {
          await deleteFavourites(token, element._id);
          toast.success("Tool removed from your Favorites");
        } else {
          await addFavourites(token, element._id);
          toast.success("Tool is added to your Favorites");
        }
        if (isFavoriteClicked) {
          setIsFavoriteClicked(!isFavoriteClicked);
        } else {
          setIsFavoriteClicked(isFavoriteClicked);
        }
      } catch (error) {
        console.error("Error adding/removing from favorites:", error);
        toast.error("An error occurred while adding/removing from favorites.");
      } finally {
        setIsFavoriteClicked(!isFavoriteClicked);
        setIsLoading(false);
      }
    } else {
      toast.error("Please log in to manage your favorites.");
    }
  };
  return (
    <div className="mt-10">
      {isLoading ? (
        <Loading isLoading={isLoading} setIsLoading={setIsLoading} />
      ) : (
        <div>
          <div className="flex flex-row gap-x-10 justify-items-center">
            <div className="bg-white w-16 h-14 md:h-16 md:w-16 rounded-lg">
              <img
                className="p-2 rounded-lg w-full"
                src={toolData.iconUrl}
                alt="tool-icon"
              />
            </div>
            <div className="flex flex-col gap-y-1 text-left">
              <p className="text-lg md:text-2xl text-[#814627] font-semibold">
                {toolData.ToolName}
              </p>
              <p className="text-xs md:text-xl text-[#814627] font-normal">
                {toolData.Category[0].category}
              </p>
            </div>
            <div className="bg-[#FFA99E] text-sm md:text-xl text-[#ffffff] px-3 my-auto mt-5 font-normal shadow-lg rounded-sm">
              <p>free</p>
            </div>
            <div
              className="ml-2 md:ml-18"
              onClick={() => handleFavoriteClick(toolData)}
            >
              {favorites &&
              favorites.some((favorite) => favorite._id === toolData._id) ? (
                <img className="w-10 h-10" src={heartFilled} alt="heart" />
              ) : (
                <img className="w-10 h-10" src={heart} alt="heart" />
              )}
            </div>
          </div>
          <div className="bg-[#FF6653BF] px-4 py-1 w-fit h-auto rounded-xl my-10 text-sm md:text-lg font-semibold">
            <a href={toolData.webLink} target="blank">
              <p>{toolData.webLink}</p>
            </a>
          </div>
        </div>
      )}{" "}
    </div>
  );
}
