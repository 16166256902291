/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  allProducts,
  getProductsByCategory,
} from "../services/products.service";
import Container from "../components/Container";
import CategoryDropDown from "../components/Home/CategoryDropDown";
import ToolCard from "../components/Home/ToolCard";
import Loading from "../components/Loading";
import Pagination from "../components/Pagination";
import Slogan from "../components/Slogan";
import RankingToolCard from "../components/Ranking/RankingToolCard";
import { useNavigate, useParams } from "react-router-dom";
import BreadCrumbs from "../components/BreadCrumbs";

export default function Ranking() {
  const [productsData, setProductsData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isToolLoading, setIsToolLoading] = useState(false);
  const [categoriesToolData, setCategoriesToolData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [productsDataMobile, setProductsDataMobile] = useState([]);

  const itemsPerPage = 6;
  const [currentPage, setCurrentPage] = useState(1);
  const totalItems = categoriesToolData.length;
  const handleNavigate = useNavigate();

  const getAllProducts = async () => {
    try {
      setIsLoading(true);
      const { data } = await allProducts();
      const productData = data.data.products;
      setProductsData(productData);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  useEffect(() => {
    if (productsData.length > 0) {
      setCategoriesData();
      setSelectedCategory(categoriesToolData[0]);
      getToolsByCategory(categories[0]);
      getToolsByCategoryMobile();
    }
  }, [productsData]);

  const setCategoriesData = () => {
    const categoryNamesSet = new Set();
    productsData.forEach((product) => {
      product.Category.forEach((category) => {
        categoryNamesSet.add(category.category);
      });
    });
    const categoryNamesArray = Array.from(categoryNamesSet);
    setCategories(categoryNamesArray);
  };

  const handleCategoryChange = async (selectedCategory) => {
    try {
      setSelectedCategory(selectedCategory);
      await getToolsByCategory(selectedCategory);
    } catch (error) {
      console.log(error);
    }
  };

  const getToolsByCategory = async (selectedCategory) => {
    try {
      setIsToolLoading(true);
      const { data } = await getProductsByCategory(selectedCategory);
      const productData = data?.data;
      setCategoriesToolData(productData[`${selectedCategory}`]);
      setIsToolLoading(false);
      setCurrentPage(1);
    } catch (error) {
      console.log(error);
    }
  };

  const getToolsByCategoryMobile = async () => {
    try {
      setIsToolLoading(true);
      const { data } = await getProductsByCategory("Productivity");
      const productData = data?.data;
      setProductsDataMobile(productData["Productivity"]);
      setSelectedCategory("Productivity");
      setCurrentPage(1);
      setIsToolLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, totalItems);

  return (
    <div>
      <div className="mt-[5rem] pb-[5rem]">
        <Container>
          <BreadCrumbs category={"Ranking"} />

          <p className="text-4xl md:text-7xl text-[#7D4E35] text-left">
            Trending Tools
          </p>
          <p className="text-lg md:text-3xl text-[#7D4E35BF] text-left pt-5">
            Unveiling the Traffic Titans in Each Category
          </p>
          {isLoading ? (
            <Loading isLoading={isLoading} className={"my-20"} />
          ) : (
            <div>
              <div className="mt-10 flex flex-row gap-5">
                <div className="hidden md:flex md:basis-3/12">
                  <CategoryDropDown
                    onSelectCategory={handleCategoryChange}
                    categoryData={categories}
                  />
                </div>
                {isToolLoading ? (
                  <Loading isLoading={isToolLoading} className={"my-20"} />
                ) : (
                  <div className="md:basis-8/12">
                    <div className="hidden md:flex flex-col gap-5">
                      {Array.isArray(categoriesToolData) &&
                        categoriesToolData
                          .slice(startIndex, endIndex)
                          .map((element, index) => (
                            <RankingToolCard
                              SelectedCategory={selectedCategory}
                              element={element}
                              index={startIndex + index}
                            />
                          ))}
                      <Pagination
                        totalItems={totalItems}
                        itemsPerPage={itemsPerPage}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                        className={"mt-auto"}
                      />
                    </div>
                    <div className="md:hidden flex flex-col gap-5">
                      {Array.isArray(productsDataMobile) &&
                        productsDataMobile
                          .slice(startIndex, endIndex)
                          .map((element, index) => (
                            <RankingToolCard
                              SelectedCategory={selectedCategory}
                              element={element}
                              index={startIndex + index}
                            />
                          ))}
                      <Pagination
                        totalItems={totalItems}
                        itemsPerPage={itemsPerPage}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                        className={"mt-auto"}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </Container>
      </div>
      <div
        onClick={() => handleNavigate("/AllRanking")}
        className="md:hidden left-8 bottom-5 w-10/12 mx-auto fixed text-[#69422D] bg-[#FF9789] rounded-lg shadow-2xl"
      >
        <p className="text-xl text-center px-5 py-2">Category-Wise Rank</p>
      </div>
    </div>
  );
}
