/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { fetchToolsAPI } from "../../services/products.service";
import Container from "../Container";
import Loading from "../Loading";
import { useNavigate } from "react-router-dom";

export default function SearchToolPage() {
  const [toolname, setToolName] = useState("");
  const handleNavigate = useNavigate();
  const [toolData, setToolData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const getShortenedDescription = (description) => {
    return description.length > 100
      ? description.substring(0, 100) + " ..."
      : description;
  };
  const getShortendUrl = (weburl) => {
    return weburl.length > 30 ? weburl.substring(0, 30) + "..." : weburl;
  };
  const getShorttoolName = (toolName) => {
    return toolName.length > 15 ? toolName.substring(0, 15) + "..." : toolName;
  };
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const getToolName = searchParams.get("searchToolName");
    const slugConversion = getToolName.toLowerCase().replace(/ /g, "-");
    if (slugConversion) {
      setToolName(slugConversion);
    } else {
      setToolName("");
    }
  }, []);
  useEffect(() => {
    const searchTools = async () => {
      try {
        setIsLoading(true);
        const { data } = await fetchToolsAPI(toolname);
        setToolData(data?.data);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    if (toolname) {
      searchTools();
    }
  }, [toolname]);

  return (
    <div className="mt-[3rem]">
      {toolData == null ? (
        <Loading isLoading={isLoading} className={"my-20"} />
      ) : (
        <div>
          <Container>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
              {toolData.map((el, index) => (
                <div
                  className="flex flex-col justify-start p-8 bg-[#FFDDCA]  gap-2 rounded-xl mb-20"
                  // onClick={() => handleNavigate(`/tool/${el.slug}`)}
                >
                  <div className="flex flex-row w-fit gap-5">
                    <img
                      src={el.iconUrl}
                      alt="icon"
                      className="mx-auto h-10 w-10"
                    />
                    <p
                      className="text-[#A43513] text-base sm:text-lg md:text-xl lg:text-2xl cursor-pointer my-auto"
                      onClick={() =>
                        handleNavigate(`/tool/${el.slug}`, {
                          state: { selectedCategory: el.Category[0].category },
                        })
                      }
                    >
                      {getShorttoolName(el.ToolName)}
                    </p>
                  </div>
                  <a href={el.webLink} target="_blank" className="mt-5">
                    <p className="bg-[#FF8471] text-white text-base rounded-xl text-center p-4 cursor-pointer">
                      {getShortendUrl(el.webLink)}
                    </p>
                  </a>
                  <img
                    src={el.imageUrl}
                    alt={el.imageUrl}
                    className="mt-5 rounded-lg"
                  />
                  <p
                    className="mt-5 md:mt-6 text-[#3D4754] text-lg md:text-xl text-left cursor-pointer"
                    onClick={() =>
                      handleNavigate(`/tool/${el.slug}`, {
                        state: { selectedCategory: el.Category[0].category },
                      })
                    }
                  >
                    {getShortenedDescription(el.Description)}
                  </p>
                </div>
              ))}
            </div>
          </Container>
        </div>
      )}
    </div>
  );
}
