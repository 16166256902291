import axios from "axios";
import { url } from "./url";

export function allProducts() {
  return axios.get(`${url}/product`);
}

export function getProductBySlug(slug) {
  return axios.get(`${url}/product/slug/${slug}`);
}

export function getProductsByCategory(categoryName) {
  return axios.get(`${url}/product/category/${categoryName}`);
}

export function getAllCategories() {
  return axios.get(`${url}/product/all/category`);
}

export function fetchToolsAPI(toolname) {
  return axios.get(`${url}/product/search/${toolname}`);
}
