/* eslint-disable react-hooks/exhaustive-deps */
import { useParams } from "react-router-dom";
import Slogan from "../components/Slogan";
import { getProductsByCategory } from "../services/products.service";
import { useEffect } from "react";
import { useState } from "react";
import Loading from "../components/Loading";
import ToolCard from "../components/Home/ToolCard";
import Container from "../components/Container";
import BreadCrumbs from "../components/BreadCrumbs";

export default function SingleCategory({
  setIsFavoriteClicked,
  favorites,
  isFavoriteClicked,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [toolData, setToolData] = useState(null);
  const { categoryName } = useParams();

  const getToolData = async () => {
    try {
      setIsLoading(true);
      const data = await getProductsByCategory(categoryName);
      const categoryData = data?.data.data;
      const tools = categoryData[categoryName];
      setToolData(tools);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getToolData();
  }, [categoryName]);
  return (
    <div>
      <Container>
        <BreadCrumbs category={"Category"} toolName={categoryName} />
      </Container>
      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : (
        <div className="mt-[5rem] pb-10">
          {toolData !== null && (
            <div>
              <Slogan />
              <Container className={"mt-10"}>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
                  {toolData.map((ele, index) => {
                    return (
                      <ToolCard
                        element={ele}
                        SelectedCategory={categoryName}
                        favorites={favorites}
                        setIsFavoriteClicked={setIsFavoriteClicked}
                        isFavoriteClicked={isFavoriteClicked}
                      />
                    );
                  })}
                </div>
              </Container>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
