export default function CardComponent({ className, children }) {
    return (
      <>
        <div
          className={`shadow-md rounded-3xl ${className}`}          
        >
          {children}
        </div>
      </>
    );
  }
  