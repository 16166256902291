import axios from "axios";
import { url } from "./url";

export default function signupUser(data) {
  return axios.post(`${url}/auth/signup`, data);
}

export function loginUser(data) {
  return axios.post(`${url}/auth/login`, data);
}

export function getSingleUser(id) {
  return axios.get(`${url}/auth/${id}`);
}

export function verifyOTP(data) {
  return axios.post(`${url}/auth/verifyOTP`, data);
}
// export function sendOTPToMail(data) {
//   return axios.post(`${url}/auth/sendOTP`, data);
// }
