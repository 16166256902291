export default function SimilarToolsCard({ toolData }) {
  const getShortenedDescription = (description) => {
    return description.length > 100
      ? description.substring(0, 100) + "..."
      : description;
  };
  const getShortendToolName = (ToolName) => {
    return ToolName.length > 10 ? ToolName.substring(0, 10) + "..." : ToolName;
  };

  return (
    <a href={toolData.webLink} target="_blank" rel="noreferrer">
      <div className="bg-[#FFDBCE] flex flex-col text-[#814627] w-10/12 mx-auto gap-2 rounded-xl p-2">
        <div className="flex flex-row gap-5">
          <img src={toolData.iconUrl} alt="iconImage" className="w-8 h-8" />

          <div className="font-semibold text-sm my-auto">
            <p>{getShortendToolName(toolData.ToolName)}</p>
          </div>
          <div className="ml-20 md:ml-0 bg-[#FFA99E] p-1 w-fit h-fit rounded-md">
            <p className="text-xs">Free</p>
          </div>
        </div>
        <div className="text-[#5E371A] hidden md:flex">
          <p className="text-xs text-left">
            {getShortenedDescription(toolData.Description)}
          </p>
        </div>
      </div>
    </a>
  );
}
