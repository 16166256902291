import { useLocation } from "react-router-dom";
import ComparisonCard from "../components/Comparison/ComparisonCard";
import Container from "../components/Container";

export default function ComparisonPage() {
  const { state } = useLocation();
  const { toolData, element , category } = state;
  return (
    <div className="mt-[5rem] pb-[4rem]">
      <p className="text-[#983500] text-3xl md:text-8xl pb-10">
        {toolData.ToolName} v/s {element.ToolName}
      </p>
      <Container>
        <div className="w-full md:w-10/12 mx-auto">
          <ComparisonCard category={category} />
        </div>
      </Container>
    </div>
  );
}
