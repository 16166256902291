import { useNavigate } from "react-router-dom";

export default function BreadCrumbs({ category, toolName }) {
  const handleNavigate = useNavigate();

  if (!category && !toolName) {
    return null;
  }

  return (
    <div className="text-sm md:text-xl font-normal text-[#5E321A] flex flex-row gap-[5px] cursor-pointer">
      <p onClick={() => handleNavigate("/")}>Home</p>
      {category && (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="#5E321A"
            className="w-6 h-6 md:w-8 md:h-8"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8.25 4.5l7.5 7.5-7.5 7.5"
            />
          </svg>
          <p>{category}</p>
        </>
      )}
      {toolName && (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="#5E321A"
            className="w-6 h-6 md:w-8 md:h-8"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8.25 4.5l7.5 7.5-7.5 7.5"
            />
          </svg>
          <p>{toolName}</p>
        </>
      )}
    </div>
  );
}
