import { useNavigate } from "react-router-dom";

export default function Card({ className, toolData, element ,favorites,setIsFavoriteClicked ,isFavoriteClicked}) {
    const handleNavigate = useNavigate();
  return (
    <div
      onClick={()=> handleNavigate(`/categories/${element}`)}className={`bg-[#FFA05B40] text-sm text-left py-5 text-[#533423] cursor-pointer ${className}`}
    >
      <div className="w-10/12 mx-auto">
        {/* <div className="">
          { data && 
            
              <div className="bg-white w-full rounded-lg">
                <img
                className="w-full mx-auto p-1"
                src={data.iconUrl}
                alt="iconImage"
              />
              </div>
            
          }
        </div> */}

        <p className="text-2xl text-center font-semibold">{element}</p>
      </div>
    </div>
  );
}
