export default function Slogan() {
  return (
    <div className="ml-10 mt-0 md:mt-[5rem] text-left md:text-center">
      <p className="hidden text-center mx-auto md:flex text-2xl md:text-6xl font-semibold text-[#7D4E35] ml-20">
        Hop into AI World:<span className="text-[#7D4E35BF]">Your All-in-One Toolkit</span>
      </p>
      <p className="md:hidden text-2xl md:text-6xl font-semibold text-[#7D4E35]">
        Hop into AI World:
      </p>
      <p className="md:hidden text-2xl md:text-6xl font-semibold text-[#7D4E35BF]">Your All-in-One Toolkit</p>
      <p className="text-sm md:text-2xl font-normal text-[#7D4E35BF] mt-3 md:mt-5">
        Discover an AI Galaxy of Limitless Innovation.
      </p>
    </div>
  );
}
