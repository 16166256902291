export const getLocalStorage = (key) => {
  if (window !== "undefined") {
    return JSON.parse(localStorage.getItem(key));
  }
};

export const isAuth = () => {
  if (window !== "undefined") {
    if (getLocalStorage("try_rabbit_cred")) {
      return getLocalStorage("try_rabbit_cred");
    } else {
      return false;
    }
  }
};
