import { useLocation, useParams } from "react-router-dom";
import { getProductBySlug } from "../services/products.service";
import { useEffect } from "react";
import { useState } from "react";
import Container from "../components/Container";
import ToolsHeader from "../components/LandingPage/ToolsHeader";
import ToolsMainSection from "../components/LandingPage/ToolsMainSection";
import Loading from "../components/Loading";
import BreadCrumbs from "../components/BreadCrumbs";
import Comparison from "../components/LandingPage/Comparison";

export default function ToolLandingPage({
  favorites,
  setIsFavoriteClicked,
  isFavoriteClicked
}) {
  const [toolData, setToolData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { slug } = useParams();
  const location = useLocation();
  const selectedCategory = location.state?.selectedCategory;
  useEffect(() => {
    const fetchCourseBySlug = async () => {
      try {
        setIsLoading(true);
        const { data } = await getProductBySlug(slug);
        const productData = data?.data?.product;
        setToolData(productData);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchCourseBySlug();
  }, [slug]);

  return (
    <div className="mt-[3rem]">
      {toolData == null ? (
        <Loading isLoading={isLoading} className={"my-20"} />
      ) : (
        <div>
          <Container>
            <BreadCrumbs
              category={selectedCategory}
              toolName={toolData.ToolName}
            />
            <ToolsHeader toolData={toolData} setIsFavoriteClicked={setIsFavoriteClicked} favorites={favorites} isFavoriteClicked={isFavoriteClicked} />
            <ToolsMainSection toolData={toolData} category={selectedCategory} />
            <Comparison toolData={toolData} category={selectedCategory} />
          </Container>
        </div>
      )}
    </div>
  );
}
