import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

export default function UserButton({ user, setRender, setUser }) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const handleNavigate = useNavigate();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem("try_rabbit_cred");
    setRender((prev) => !prev);
    setUser(null);
  };

  const closeDropdown = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeDropdown);

    return () => {
      document.removeEventListener("mousedown", closeDropdown);
    };
  }, []);

  return (
    <div className="relative text-black" ref={dropdownRef}>
      <button
        className="bg-white text-black font-semibold py-2 px-4 rounded-full focus:outline-none focus:ring"
        onClick={toggleDropdown}
      >
        {user?.user.fullName
          .split(" ")
          .map((name) => name.charAt(0).toUpperCase())}
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-40 bg-white border border-gray-200 rounded shadow z-50">
          <ul>
            <li
              className="py-2 px-4 hover:bg-gray-100 cursor-pointer font-medium text-sm"
              onClick={() => {
                handleNavigate("/favourites");
                toggleDropdown();
              }}
            >
              My Favourites
            </li>

            <li
              className="py-2 px-4 hover:bg-gray-100 cursor-pointer font-medium text-sm"
              onClick={handleLogout}
            >
              Logout
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}
