/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  addFavourites,
  deleteFavourites,
  getMyFavourites,
} from "../../services/favourite.service";
import { toast } from "react-hot-toast";
import Loading from "../Loading";
import { isAuth } from "../../helpers/authHelper";
import { useEffect } from "react";
import heart from "../../assets/svgs/heart.svg";
import heartFilled from "../../assets/svgs/heartFilled.svg";

const ToolCard = React.memo(
  ({
    element,
    SelectedCategory,
    userToken,
    favorites,
    setIsFavoriteClicked,
    isFavoriteClicked,
  }) => {
    const handleNavigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const getShortenedDescription = (description) => {
      return description.length > 100
        ? description.substring(0, 100) + " ..."
        : description;
    };

    const getShortenedUrl = (Url) => {
      return Url.length > 50 ? Url.substring(0, 50) + " ..." : Url;
    };
    const getShortToolName = (ToolName) => {
      return ToolName.length > 15
        ? ToolName.substring(0, 15) + "..."
        : ToolName;
    };

    const { token } = isAuth();

    const [heartFillColor, setHeartFillColor] = useState("#FFA99E");

    useEffect(() => {
      if (
        favorites &&
        favorites.some((favorite) => favorite._id === element._id)
      ) {
        setHeartFillColor("#FFA99E");
      } else {
        setHeartFillColor("none");
      }
    }, [favorites, element._id]);

    const handleFavoriteClick = async (element) => {
      const isFavorite =
        favorites && favorites.some((favorite) => favorite._id === element._id);
      if (token) {
        setIsLoading(true);
        try {
          if (isFavorite) {
            await deleteFavourites(token, element._id);
            toast.success("Tool removed from your Favorites");
            setHeartFillColor("none");
          } else {
            await addFavourites(token, element._id);
            toast.success("Tool is added to your Favorites");
            setHeartFillColor("#FFA99E");
          }
          if (isFavoriteClicked) {
            setIsFavoriteClicked(!isFavoriteClicked);
          } else {
            setIsFavoriteClicked(isFavoriteClicked);
          }
        } catch (error) {
          console.error("Error adding/removing from favorites:", error);
          toast.error(
            "An error occurred while adding/removing from favorites."
          );
        } finally {
          setIsLoading(false);
          setIsFavoriteClicked(!isFavoriteClicked);
        }
      } else {
        toast.error("Please log in to manage your favorites.");
      }
    };

    return (
      <div className="product-card text-[#A43513] h-auto text-lg px-2 py-10 hover:origin-center">
        {isLoading ? (
          <div className="inset-0 flex items-center justify-center bg-opacity-70 z-10">
            <Loading isLoading={isLoading} className={"mt-[5rem]"} />
          </div>
        ) : (
          <div>
            <div className="flex flex-row justify-between h-8 md:h-5 w-10/12 md:w-11/12 mx-auto">
              <div className="grid grid-cols-12 gap-x-0 md:gap-x-3 w-8/12 justify-items-center align-middle mx-auto">
                <div className="col-span-2  w-8 h-8 md:h-11 md:w-11 rounded-lg">
                  <img
                    className="p-2 rounded-lg w-full h-full"
                    src={element.iconUrl}
                    alt="tool-icon"
                  />
                </div>
                <p
                  className="col-span-8 text-sm md:text-sm cursor-pointer my-auto"
                  onClick={() =>
                    handleNavigate(`/tool/${element.slug}`, {
                      state: {
                        selectedCategory: SelectedCategory,
                      },
                    })
                  }
                >
                  {getShortToolName(element.ToolName)}
                </p>
                <div className="col-span-2 bg-[#FFA99E] text-xs text-[#ffffff] font-normal shadow-lg rounded-lg h-6 w-10 p-1 flex items-center my-auto">
                  <p className="w-[40px]">Free</p>
                </div>
              </div>
              <div
                className="ml-2 md:ml-18"
                onClick={() => handleFavoriteClick(element)}
              >
                {favorites &&
                favorites.some((favorite) => favorite._id === element._id) ? (
                  <img className="w-10 h-10" src={heartFilled} alt="heart" />
                ) : (
                  <img className="w-10 h-10" src={heart} alt="heart" />
                )}
              </div>
            </div>
            <div
              className="w-10/12 mx-auto mt-8 md:mt-10 cursor-pointer"
              onClick={() =>
                handleNavigate(`/tool/${element.slug}`, {
                  state: { selectedCategory: SelectedCategory },
                })
              }
            >
              <img
                src={element.imageUrl}
                alt={element.imageUrl}
                className="mt-5 md:mt-16 rounded-lg  border-[6px] border-[#8B433A80] shadowCardHome "
              />
            </div>
            <div
              className="mt-8 md:mt-10 w-10/12 mx-auto h-[4rem] cursor-pointer"
              onClick={() =>
                handleNavigate(`/tool/${element.slug}`, {
                  state: { selectedCategory: SelectedCategory },
                })
              }
            >
              <p className="text-xs text-left">
                {getShortenedDescription(element.Description)}
              </p>
            </div>
            <div className="bg-[#FF6653BF] w-10/12 mx-auto rounded-xl mt-5 md:mt-10 text-[8px] font-semibold text-white tracking-wide">
              <a href={element.webLink} target="blank">
                <p>{getShortenedUrl(element.webLink)}</p>
              </a>
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default ToolCard;
