import { useNavigate } from "react-router-dom";
import cup from "../../assets/svgs/cup.svg";
export default function RankingToolCard({ element, SelectedCategory, index }) {
  const handleNavigate = useNavigate();
  const getShortenedDescription = (description) => {
    return description.length > 100
      ? description.substring(0, 100) + " ..."
      : description;
  };
  const getShortUrl = (weburl) => {
    return weburl.length > 20 ? weburl.substring(0, 20) + "..." : weburl;
  };

  return (
    <div className="product-card text-[#A43513] h-auto text-lg px-5 py-2 grid grid-rows gap-y-5 md:grid-cols-3">
      <div className="grid grid-cols-10 h-10 my-auto">
        <div className="col-span-2 w-10 relative">
          <img src={cup} alt="rankImage" />
          <span className="absolute top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-[#5E321A] text-xs font-semibold">
            {index + 1}
          </span>
        </div>
        <div className="col-span-2  w-10 h-10 rounded-lg">
          <img
            className="w-full h-full m-auto p-1"
            src={element.iconUrl}
            alt="iconImage"
          />
        </div>
        <p className="col-span-4 text-xs font-bold px-2 my-auto">
          {element.ToolName}
        </p>
        <div className="col-span-2 bg-[#FFA99E] text-xs text-[#ffffff] font-normal shadow-lg rounded-lg h-6 p-1 flex items-center my-auto">
          <p className="w-[40px]">Free</p>
        </div>
      </div>
      <div className="w-9/12 mx-auto my-auto h-[4rem]">
        <p className="text-xs text-left">
          {getShortenedDescription(element.Description)}
        </p>
      </div>
      <div className="flex flex-col gap-y-[10px]">
        <div
          className="bg-[#FFCBBA] rounded-xl w-full cursor-pointer"
          onClick={() =>
            handleNavigate(`/tool/${element.slug}`, {
              state: { selectedCategory: SelectedCategory },
            })
          }
        >
          <p className="px-10 py-5 text-[#814627] text-xs font-semibold">
            More About {element.ToolName}
          </p>
        </div>
        <div className="bg-[#FF6653BF] mx-auto w-10/12 rounded-xl text-[8px] font-semibold text-white tracking-wide">
          <a href={element.webLink} target="blank">
            <p>{getShortUrl(element.webLink)}</p>
          </a>
        </div>
      </div>
    </div>
  );
}
