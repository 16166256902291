import axios from "axios";
import { url } from "./url";

export default function postTools(requestedData, token, userId) {
  return axios.post(`${url}/submit/PostTools/${userId}`, requestedData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getTools(token) {
  return axios.get(`${url}/submit/ToolList`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
