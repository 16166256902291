import { useEffect } from "react";
import Container from "../components/Container";
import {
  getAllCategories,
  getProductsByCategory,
} from "../services/products.service";
import { useState } from "react";
import Card from "../components/Category/Card";
import Loading from "../components/Loading";
import BreadCrumbs from "../components/BreadCrumbs";

export default function Category({setIsFavoriteClicked ,favorites , isFavoriteClicked}) {
  const [categoriesData, setCategoriesData] = useState(null);
  const [toolData, setToolData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const getAllCategoryNames = async () => {
    try {
      const data = await getAllCategories();
      const categories = data?.data?.data?.categories;
      setCategoriesData(categories);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllIcons = async (categoryName) => {
    try {
      setIsLoading(true);
      const data = await getProductsByCategory(categoryName);
      setToolData(data?.data.data.Productivity);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllCategoryNames();
  }, []);
  useEffect(() => {
    getAllIcons("Productivity");
  }, []);
  return (
    <div>
      <Container>
        <BreadCrumbs category={"Category"} />
      </Container>
      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : (
        <div className="mt-[5rem] pb-10">
          {categoriesData && toolData && (
            <Container>
              <div className="text-left">
                <p className="text-[#7D4E35] font-semibold text-5xl">
                  All Categories
                </p>
                <p className="text-[#7D4E35BF] text-xl pt-[10px]">
                  The Power of Artificial Intelligence for Every Use Case
                </p>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-3 gap-5 mt-10">
                {categoriesData.map((element) => {
                  return (
                    <Card
                      element={element}
                      toolData={toolData}
                      className={"rounded-xl"}
                      favorites={favorites}
                      setIsFavoriteClicked={setIsFavoriteClicked}
                      isFavoriteClicked={isFavoriteClicked}
                    />
                  );
                })}
              </div>
            </Container>
          )}
        </div>
      )}
    </div>
  );
}
