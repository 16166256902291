/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  allProducts,
  getAllCategories,
  getProductsByCategory,
} from "../services/products.service";
import Container from "../components/Container";
import CategoryDropDown from "../components/Home/CategoryDropDown";
import ToolCard from "../components/Home/ToolCard";
import Loading from "../components/Loading";
import Pagination from "../components/Pagination";
import Slogan from "../components/Slogan";
import { isAuth } from "../helpers/authHelper";
import SearchBar from "../components/Home/SearchBar";
import { useNavigate } from "react-router-dom";
import { getMyFavourites } from "../services/favourite.service";

export default function Home({
  setIsFavoriteClicked,
  favorites,
  isFavoriteClicked,
}) {
  const [productsData, setProductsData] = useState([]);
  const [productsDataMobile, setProductsDataMobile] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isToolLoading, setIsToolLoading] = useState(false);
  const [categoriesToolData, setCategoriesToolData] = useState([]);
  const [categoriesToolDataMobile, setCategoriesToolDataMobile] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const handleNavigate = useNavigate();

  const itemsPerPage = 6;
  const [currentPage, setCurrentPage] = useState(1);
  const totalItems = categoriesToolData.length;

  const { user, token } = isAuth();
  const getAllProducts = async () => {
    try {
      setIsLoading(true);
      const { data } = await allProducts();
      const productData = data.data.products;
      setProductsData(productData);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  useEffect(() => {
    if (productsData) {
      setCategoriesData();
      setSelectedCategory(categories[0]);
      getToolsByCategory(categories[0]);
      getToolsByCategoryMobile();
    }
  }, [productsData]);

  const setCategoriesData = async () => {
    const data = await getAllCategories();
    const categories = data?.data?.data?.categories;
    setCategories(categories);
  };

  const handleCategoryChange = async (selectedCategory) => {
    try {
      setSelectedCategory(selectedCategory);
      await getToolsByCategory(selectedCategory);
    } catch (error) {
      console.log(error);
    }
  };

  const getToolsByCategory = async (selectedCategory) => {
    try {
      setIsToolLoading(true);
      const { data } = await getProductsByCategory(selectedCategory);
      const productData = data?.data;
      setCategoriesToolData(productData[`${selectedCategory}`]);
      setCurrentPage(1);
      setIsToolLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getToolsByCategoryMobile = async () => {
    try {
      setIsToolLoading(true);
      const { data } = await getProductsByCategory("Productivity");
      const productData = data?.data;
      setProductsDataMobile(productData["Productivity"]);
      setCurrentPage(1);
      setIsToolLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, totalItems);

  return (
    <div>
      <Slogan />
      <div className="pb-[5rem] w-11/12 mx-auto">
        {isLoading ? (
          <Loading isLoading={isLoading} className={"my-20"} />
        ) : (
          <div>
            <SearchBar />
            <div className=" grid grid-cols-12 gap-5">
              <div className="hidden md:grid col-span-3">
                <CategoryDropDown
                  onSelectCategory={handleCategoryChange}
                  categoryData={categories}
                />
              </div>
              {isToolLoading ? (
                <Loading isLoading={isToolLoading} className={"mx-auto"} />
              ) : (
                <>
                  <div className="col-span-12 md:col-span-9">
                    <div className="hidden md:grid md:grid-cols-3 gap-5">
                      {categoriesToolData.length > 0 &&
                        categoriesToolData
                          .slice(startIndex, endIndex)
                          .map((element) => (
                            <ToolCard
                              SelectedCategory={selectedCategory}
                              element={element}
                              userToken={token}
                              favorites={favorites}
                              setIsFavoriteClicked={setIsFavoriteClicked}
                              isFavoriteClicked={isFavoriteClicked}
                            />
                          ))}
                    </div>
                    <div className="grid grid-cols-1 md:hidden gap-5">
                      {productsDataMobile.length > 0 &&
                        productsDataMobile
                          .slice(startIndex, endIndex)
                          .map((element) => (
                            <ToolCard
                              SelectedCategory={selectedCategory}
                              element={element}
                              userToken={token}
                              setIsFavoriteClicked={setIsFavoriteClicked}
                              isFavoriteClicked={isFavoriteClicked}
                            />
                          ))}
                    </div>
                    <Pagination
                      totalItems={totalItems}
                      itemsPerPage={itemsPerPage}
                      currentPage={currentPage}
                      onPageChange={handlePageChange}
                      className={"pt-5"}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
      <div
        onClick={() => handleNavigate("/AllCategories")}
        className="md:hidden left-8 bottom-5 w-10/12 mx-auto fixed text-[#69422D] bg-[#FF9789] rounded-lg shadow-2xl"
      >
        <p className="text-xl text-center px-5 py-2">Search Category-Wise</p>
      </div>
    </div>
  );
}
