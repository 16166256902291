import SimilarToolsCard from "./SimilarToolsCard";

export default function SimilarTools({ toolData, selectedIndex }) {
    if (!Array.isArray(toolData)) {
      return null; // Return null if toolData is not an array yet
    }
  
    const startIndex = selectedIndex != null ? Math.max(0, selectedIndex) : 0;
    const endIndex = startIndex + 4;
  
    const slicedToolData = toolData.slice(startIndex+1, endIndex);
  
    return (
      <div className="bg-[#FFCCB9] rounded-2xl text-xl font-normal py-5">
        <div className="w-11/12 mx-auto">
          <p className="text-[#814627] text-sm pb-5">Browse other similar Tools</p>
        </div>
        <div className="flex flex-col gap-y-5">
          {slicedToolData.map((element) => {
            return (
                <SimilarToolsCard toolData={element}/>
            )
          })}
        </div>
      </div>
    );
  }
  