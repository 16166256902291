import rightBall from "../assets/pngs/rightBall.png";
import leftBall from "../assets/svgs/leftBall.svg";
import Container from "../components/Container";
import { options } from "../provider/SubmitCategorySelection";
import SubmitImage from "../assets/svgs/SubmitImage.svg";
import { useState } from "react";
import postTools from "../services/submit.service";
import ToastProvider from "../provider/ToastProvider";
import { toast } from "react-hot-toast";
import { useEffect } from "react";
import BreadCrumbs from "../components/BreadCrumbs";
export default function SubmitPage() {
  const [input, setInputs] = useState({
    ToolName: "",
    CategoryName: options[0],
    ToolDescription: "",
    keyword1: "",
    keyword2: "",
    keyword3: "",
    price1: "",
    price2: "",
    price3: "",
    price4: "",
    price5: "",
    webLink: "",
  });
  const [userId, setUserID] = useState("");

  useEffect(() => {
    const { user } = JSON.parse(localStorage.getItem("try_rabbit_cred")) || {};
    if (user) {
      setUserID(user._id);
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs({ ...input, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !input.CategoryName ||
      !input.ToolDescription ||
      !input.ToolName ||
      !input.keyword1 ||
      !input.webLink
    ) {
      toast.error("Please fill all the required field");
    } else {
      try {
        const { token } = JSON.parse(localStorage.getItem("try_rabbit_cred"));
        const requestedData = {
          ...input,
        };
        const { data } = await postTools(requestedData, token, userId);
        toast.success(data?.message || "Tool has been Submitted Successfully");
        setInputs({
          ToolName: "",
          CategoryName: options[0],
          ToolDescription: "",
          keyword1: "",
          keyword2: "",
          keyword3: "",
          price1: "",
          price2: "",
          price3: "",
          price4: "",
          price5: "",
          webLink: "",
        });
      } catch (error) {
        toast.error(error.response.data.message || "Something went wrong");
        setInputs({
          ToolName: "",
          CategoryName: options[0],
          ToolDescription: "",
          keyword1: "",
          keyword2: "",
          keyword3: "",
          price1: "",
          price2: "",
          price3: "",
          price4: "",
          price5: "",
          webLink: "",
        });
      }
    }
  };

  return (
    <>
      <ToastProvider></ToastProvider>
      <div className="mb-20">
        <img
          src={rightBall}
          alt="right-ball"
          className="absolute top-10 -right-[10rem] -z-10 w-4/12"
        />
        <Container className="mt-10">
          <BreadCrumbs category={"Submit"} />
          <form onSubmit={handleSubmit}>
            <div className="rounded-xl p-8 submitbg login-text mt-10">
              <div className="text-left">
                <p className="text-[#7D4E35] font-semibold text-5xl mt-10">
                  Submit new AI Tools
                </p>
                <p className="text-[#7D4E35BF] text-xl pt-[10px] mt-5">
                  Unveiling the Traffic Titans in Each Category
                </p>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-10 mt-10 text-sm text-left ">
                <div className="flex flex-col gap-2 ">
                  <lable className="text-[#802C00] relative ">
                    Enter the name of the tool
                    <span class="absolute text-[#e74b4b]  ml-2"> *</span>
                  </lable>
                  <input
                    type="text"
                    name="ToolName"
                    value={input.ToolName}
                    onChange={handleChange}
                    className="w-full h-10 p-4 rounded-lg"
                    placeholder="ExampleGPT"
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <lable className="text-[#802C00] relative">
                    Select the Category the Tool belongs to:
                    <span class="absolute text-[#e74b4b]  ml-2"> *</span>
                  </lable>
                  <select
                    name="CategoryName"
                    id="categorySelect"
                    className="w-full h-10 rounded-lg"
                    value={input.CategoryName}
                    onChange={handleChange}
                  >
                    {options.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex flex-col gap-2">
                  <lable className="text-[#802C00] relative">
                    Mention the link of the Home Page of the tool
                    <span class="absolute text-[#e74b4b]  ml-2">*</span>
                  </lable>
                  <input
                    type="url"
                    name="webLink"
                    value={input.webLink}
                    onChange={handleChange}
                    className="w-full h-10 p-4 rounded-lg"
                    placeholder="www.exampleGPT.com/home"
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-1 md:gap-10 mt-10 text-sm text-left login-text">
                <div className="flex flex-col gap-2">
                  <lable className="text-[#802C00] relative ">
                    Mention 3 Keywords about the AI Tool
                    <span class="absolute text-[#e74b4b]  ml-2">*</span>
                  </lable>
                  <input
                    type="text"
                    name="keyword1"
                    value={input.keyword1}
                    onChange={handleChange}
                    className="w-full h-10 p-4 rounded-lg"
                    placeholder="keyword#1"
                  />
                </div>
                <input
                  type="text"
                  name="keyword2"
                  value={input.keyword2}
                  onChange={handleChange}
                  className="w-full h-10 p-4 rounded-lg mt-[1.75rem]"
                  placeholder="keyword#2"
                />
                <input
                  type="text"
                  name="keyword3"
                  value={input.keyword3}
                  onChange={handleChange}
                  className="w-full h-10 p-4 rounded-lg mt-[1.75rem]"
                  placeholder="keyword#3"
                />
              </div>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-1 md:gap-10 mt-10 text-sm text-left login-text">
                <div className="flex flex-col gap-2">
                  <lable className="text-[#802C00]  ">Pricing Sections</lable>
                  <input
                    type="text"
                    name="price1"
                    value={input.price1}
                    onChange={handleChange}
                    className="w-full h-10 p-4 rounded-lg"
                    placeholder="PricingPlan#1"
                  />
                </div>
                <input
                  type="text"
                  name="price2"
                  value={input.price2}
                  onChange={handleChange}
                  className="w-full h-10 p-4 rounded-lg mt-[1.75rem]"
                  placeholder="PricingPlan#2"
                />
                <input
                  type="text"
                  name="price3"
                  value={input.price3}
                  onChange={handleChange}
                  className="w-full h-10 p-4 rounded-lg mt-[1.75rem]"
                  placeholder="PricingPlan#3"
                />
                <input
                  type="text"
                  name="price4"
                  value={input.price4}
                  onChange={handleChange}
                  className="w-full h-10 p-4 rounded-lg mt-[1.75rem]"
                  placeholder="PricingPlan#4"
                />
                <input
                  type="text"
                  name="price5"
                  value={input.price5}
                  onChange={handleChange}
                  className="w-full h-10 p-4 rounded-lg mt-[1.75rem]"
                  placeholder="PricingPlan#5"
                />
              </div>
              <div className="flex flex-col text-left mt-10 text-sm gap-2">
                <lable className="text-[#802C00] relative ">
                  Give a brief discussion on the AI Tool
                  <span class="absolute text-[#e74b4b]  ml-2">*</span>
                </lable>
                <textarea
                  cols="30"
                  rows="10"
                  name="ToolDescription"
                  value={input.ToolDescription}
                  onChange={handleChange}
                  placeholder="Enter the description"
                  className="p-4 w-full md:w-fit rounded-xl"
                />
              </div>
              <button
                type="submit"
                className="bg-[#FF8054] px-20 py-2 text-white font-semibold mx-auto flex mt-10 gap-3 rounded-xl"
              >
                <img src={SubmitImage} alt={SubmitImage} />
                <p className="text-xl text-center">Submit Tool</p>
              </button>
            </div>
          </form>
        </Container>
        <img
          src={leftBall}
          alt="left-ball"
          className="absolute -bottom-80 -left-[10rem] -z-10 w-4/12 pb-[10rem]"
        />
      </div>
    </>
  );
}
