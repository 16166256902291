/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { getProductsByCategory } from "../../services/products.service";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Container from "../Container";

export default function Comparison({ toolData, category }) {
  const [allTools, setAllTools] = useState(null);
  const [toolIndex, setToolIndex] = useState(null);
  const handleNavigate = useNavigate();

  const getAllTools = async () => {
    try {
      const { data } = await getProductsByCategory(category);
      setAllTools(data?.data[category]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllTools();
  }, []);

  useEffect(() => {
    if (allTools && toolData && toolData.ToolName) {
      const index = allTools.findIndex(
        (tool) => tool.ToolName === toolData.ToolName
      );
      setToolIndex(index);
    }
  }, [allTools, toolData]);

  const startIndex = toolIndex != null ? Math.max(0, toolIndex) : 0;
  const endIndex = startIndex + 4;

  const getShortToolName = (ToolName) => {
    return ToolName.length > 15 ? ToolName.substring(0, 15) + "..." : ToolName;
  };

  return (
    <div className="pb-[4rem]">
      <Container>
        <p className="text-xl md:text-3xl text-[#5E321A] font-normal text-left">
          Compare {toolData.ToolName} with Alternatives
        </p>
        <div className="grid grid-cols-3 gap-6 text-[#5E321A] font-normal mt-10 md:mt-[4rem]">
          {allTools &&
            allTools.slice(startIndex + 1, endIndex).map((element, index) => (
              <div
                key={index}
                className="w-fit flex flex-row gap-2  text-sm md:text-xl bg-[#FFD4BC] px-10 py-5 rounded-xl cursor-pointer"
                onClick={() =>
                  handleNavigate(
                    `/comparison/${toolData.slug}/${element.slug}`,
                    {
                      state: {
                        toolData,
                        element,
                        category,
                      },
                    }
                  )
                }
              >
                <p className="my-auto">{getShortToolName(toolData.ToolName)}</p>
                <p className="my-auto">v/s</p>
                <p className="my-auto">{getShortToolName(element.ToolName)}</p>
              </div>
            ))}
        </div>
      </Container>
    </div>
  );
}
