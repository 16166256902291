import axios from "axios";
import { url } from "./url";

export function getMyFavourites(token) {
    return axios.get(`${url}/auth/users/favourites`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }


  export function addFavourites(token , productId) {
    const params = { productId };
    return axios.post(`${url}/auth/add/favourites`, params, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  export function deleteFavourites(token , productId) {
    return axios.delete(`${url}/auth/delete/favourites/${productId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }