/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import Container from "../components/Container";
import { isAuth } from "../helpers/authHelper";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { getMyFavourites } from "../services/favourite.service";
import Loading from "../components/Loading";
import FavouritesToolCard from "../components/favourites/FavouritesToolCard";

export default function Favourites() {
  const [favourites, setFavourites] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { token } = isAuth();
  
  const fetchFavourites = async () => {
    try {
      setIsLoading(true);
      const { data } = await getMyFavourites(token);
      setFavourites(data?.data);
      setIsLoading(false)
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  
  useEffect(() => {
    if (token) {
      fetchFavourites();
    }
  }, [token]);

  return (
    <div className="mt-10 pb-[5rem]">
      <Container>
        <p className="text-2xl md:text-5xl text-[#A43513] text-left font-semibold">
          Your favourites :
          {isLoading ? (
            <Loading isLoading={isLoading} className={"mt-10"}/>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-4 gap-5 mt-10">
              {favourites.map((ele, index) => {
                return <FavouritesToolCard element={ele} favorites={favourites} fetchFavourites={fetchFavourites} />;
              })}
            </div>
          )}
        </p>
      </Container>
    </div>
  );
}
