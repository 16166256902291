export default function CarouselItems({ toolData, selectedCategory }) {
  const getShortenedDescription = (description) => {
    return description.length > 75
      ? description.substring(0, 75) + "..."
      : description;
  };
  const getShortendUrl = (url) => {
    return url.length > 30 ? url.substring(0, 30) + "..." : url;
  };
  const getShortToolName = (ToolName) => {
    return ToolName.length > 10 ? ToolName.substring(0, 10) + "..." : ToolName;
  };
  return (
    <>
      <div className="flex flex-col gap-5 rounded-2xl bg-[#FFDDCA] p-4 mt-10 h-[35,0px]">
        <div className="flex flex-row gap-5">
          <div className="bg-white w-10 h-10 rounded-lg">
            <img
              className="w-10 h-10 m-auto p-1"
              src={toolData.iconUrl}
              alt="iconImage"
            />
          </div>
          <div className="flex flex-col text-left">
            <p className="text-sm text-[#A43513] font-semibold">
              {getShortToolName(toolData.ToolName)}
            </p>
            <p className="text-xs text-[#A43513]">{selectedCategory}</p>
          </div>
          <div className="bg-[#FFA99E] text-xs text-[#ffffff] px-3 my-auto font-normal shadow-lg rounded-sm">
            <p>free</p>
          </div>
        </div>
        <img
          src={toolData.imageUrl}
          alt={toolData.imageUrl}
          className="w-10/12 mx-auto border-[#8B433A80] shadowCardCarousel border-[6px] rounded-xl"
        />
        <div className="h-[30px] text-xs text-[#A43513] text-left mx-auto">
          <p className="mx-auto">
            {getShortenedDescription(toolData.Description)}
          </p>
        </div>
        <div className="bg-[#FF6653BF] px-2 w-10/12 h-fit rounded-xl  text-[10px] font-normal mx-auto mt-5">
          <a href={toolData.webLink} target="blank">
            <p>{getShortendUrl(toolData.webLink)}</p>
          </a>
        </div>
      </div>
    </>
  );
}
