/* eslint-disable react-hooks/exhaustive-deps */
import { Routes, Route, useLocation } from "react-router-dom";

import Navbar from "./components/Navbar/Navbar";
import Login from "./Pages/Login";
import SignUp from "./Pages/SignUp";
import Footer from "./components/Footer/Footer";
import Home from "./Pages/Home";
import ToolLandingPage from "./Pages/ToolLandingPage";
import Category from "./Pages/Category";
import SingleCategory from "./Pages/SingleCategory";
import Ranking from "./Pages/Ranking";
import ComparisonPage from "./Pages/ComparisonPage";
import { useState } from "react";
import { useEffect } from "react";
import ProtectRoute from "./components/ProtectRoute";
import SubmitPage from "./Pages/SubmitPage";
import Favourites from "./Pages/Favourites";
import ForgotPassword from "./Pages/forgotPassword";
import ResetPassword from "./Pages/ResetPassword";
import SearchToolPage from "./components/Home/SearchToolPage";
import CategoriesMobile from "./Pages/CategoriesMobile";
import RankingMobile from "./Pages/RankingMobile";
import RankingMobilePage from "./Pages/RankingMobilePage";
import { getMyFavourites } from "./services/favourite.service";
import { isAuth } from "./helpers/authHelper";
function App() {
  const { pathname } = useLocation();
  const hideNavAndFooterRoutes = ["/signup", "/login"];
  const showRoutes = !hideNavAndFooterRoutes.includes(pathname);
  const [render, setRender] = useState(false);
  const [user, setUser] = useState(null);
  const [favorites, setFavourites] = useState(false);
  const [isFavoriteClicked , setIsFavoriteClicked] = useState(false)
  
  const { token } = isAuth();

  useEffect(() => {
    const user = JSON.parse(
      localStorage.getItem("try_rabbit_cred")
        ? localStorage.getItem("try_rabbit_cred")
        : null
    );

    if (user?.user) {
      setUser(user);
    }
  }, [render]);
  
  const fetchFavourites = async () => {
    try {
      const { data } = await getMyFavourites(token);
      setFavourites(data?.data);
    } catch (error) {
      console.log("Something went wrong");
    }
  };

  useEffect(() => {
    if (token) {
      fetchFavourites();
    }
  }, [isFavoriteClicked]);

  return (
    <div className="text-4xl font-bold text-center">
      {showRoutes && (
        <Navbar user={user} setRender={setRender} setUser={setUser} />
      )}
      <Routes>
        <Route element={<ProtectRoute render={render} />}>
          <Route path="/submit" element={<SubmitPage />} user={user} />
          <Route path="/favourites" element={<Favourites />} user={user} />
        </Route>
        <Route path="/" element={<Home setIsFavoriteClicked={setIsFavoriteClicked} favorites={favorites} isFavoriteClicked={isFavoriteClicked} />} />
        <Route path="/login" element={<Login setRender={setRender} />} />
        <Route path="/signup" element={<SignUp setRender={setRender} />} />
        <Route path="/resetPassword" element={<ResetPassword />} />
        <Route path="/categories" element={<Category setIsFavoriteClicked={setIsFavoriteClicked} favorites={favorites} isFavoriteClicked={isFavoriteClicked}  />} />
        <Route path="/AllCategories" element={<CategoriesMobile setIsFavoriteClicked={setIsFavoriteClicked} favorites={favorites} isFavoriteClicked={isFavoriteClicked} />} />
        <Route path="/AllRanking" element={<RankingMobile />} />
        <Route path="/ranking/:slug" element={<RankingMobilePage />} />
        <Route path="/ranking" element={<Ranking />} />
        <Route path="/comparison/:tool1/:tool2" element={<ComparisonPage />} />
        <Route path="/categories/:categoryName" element={<SingleCategory setIsFavoriteClicked={setIsFavoriteClicked} favorites={favorites} isFavoriteClicked={isFavoriteClicked}/>} />
        <Route path="/tool/:slug" element={<ToolLandingPage setIsFavoriteClicked={setIsFavoriteClicked} favorites={favorites} isFavoriteClicked={isFavoriteClicked} />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/resetPassword" element={<ResetPassword />} />
        <Route path="/searchToolPage" element={<SearchToolPage />} />
      </Routes>
      {showRoutes && <Footer />}
    </div>
  );
}

export default App;
