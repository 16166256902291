export default function ToolPrice({ priceData , classNameMain,classNameElement}) {
  return (
    <div className={`${classNameMain}`}>
      {priceData.price1 !== "" &&<p className={`${classNameElement}`}> {priceData.price1}</p>}
      {priceData.price2 !== "" &&<p className={`${classNameElement}`}> {priceData.price2}</p>}
      {priceData.price3 !== "" &&<p className={`${classNameElement}`}> {priceData.price3}</p>}
      {priceData.price4 !== "" &&<p className={`${classNameElement}`}> {priceData.price4}</p>}
      {priceData.price5 !== "" &&<p className={`${classNameElement}`}> {priceData.price5}</p>}
    </div>
  );
}
