/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { getProductsByCategory } from "../../services/products.service";
import SimilarTools from "./SimilarTools";
import ToolPrice from "./ToolPrice";
import { useState } from "react";
import Loading from "../Loading";
import CarousalToolCard from "./CarousalToolCard";

export default function ToolsMainSection({ toolData, category }) {
  const [allTools, setAllTools] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [toolIndex, setToolIndex] = useState(null);

  const getAllTools = async () => {
    try {
      setIsLoading(true);
      const { data } = await getProductsByCategory(category);
      setAllTools(data?.data[category]);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllTools();
  }, []);

  useEffect(() => {
    if (allTools && toolData && toolData.ToolName) {
      const index = allTools.findIndex(
        (tool) => tool.ToolName === toolData.ToolName
      );
      setToolIndex(index);
    }
  }, [allTools, toolData]);

  return (
    <div className="mt-[5rem] pb-10">
      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : (
        <div>
          <div className="flex flex-col md:flex-row gap-5">
            <div className="mx-auto w-full md:w-5/12">
              <img
                className="w-full md:w-11/12 h-fit rounded-lg border-[#8B433A80] border-[10px] shadowCardHome"
                src={toolData.imageUrl}
                alt="toolImage"
              />
            </div>
            <div className="w-full md:w-6/12 text-[20px] text-left font-normal text-[#3D4754] leading-snug mt-5 md:mt-0">
              <div className="w-full pb-5">
                <p>{toolData.Description}</p>
              </div>
              <div className="flex flex-col gap-y-5">
                {toolData.Keywords.map((keyword) => {
                  return (
                    <div className="bg-[#FFCCB9] px-4 py-1 rounded-lg w-fit text-lg">
                      <p>{keyword}</p>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="hidden md:flex basis-3/12">
              <SimilarTools toolData={allTools} selectedIndex={toolIndex} />
            </div>
          </div>
          <div className="mt-12">
            <p className="text-left text-[#5E321A] text-3xl font-normal">
              Pricing
            </p>
            <ToolPrice
              priceData={toolData.Prices}
              classNameMain={"flex flex-col md:flex-row gap-5 mt-10"}
              classNameElement={
                "bg-[#FFDBCE] text-[#5E321A] text-xl font-normal py-[15px] md:py-[50px] px-[100px] rounded-2xl"
              }
            />
          </div>
          <div className=" md:hidden my-10 basis-3/12">
            <SimilarTools toolData={allTools} selectedIndex={toolIndex} />
          </div>
          <div className="mt-12">
            <p className="text-left text-[#5E321A] text-xl md:text-3xl font-normal">
              Browse Similar AI Tools
            </p>
            <CarousalToolCard
              toolData={allTools}
              selectedCategory={category}
              currentTool={toolData.slug}
            />
          </div>
        </div>
      )}
    </div>
  );
}
