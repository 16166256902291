/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef } from "react";
import { useState } from "react";
import SearchIcon from "../../assets/svgs/searchIcon.svg";
import { Link, useNavigate } from "react-router-dom";

export default function SearchBar({ onClick }) {
  const [searchInput, setSearchInput] = useState({
    toolname: "",
  });
  const ref = useRef();
  const navigate = useNavigate();
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      navigate(`/searchToolPage/?searchToolName=${searchInput}`);
    }
  };

  return (
    <div
      className="mt-10 flex flex-col sm:w-7/12 md:w-6/12 lg:w-5/12 xl:w-4/12 ml-auto text-[#7D4E35] p-4 rounded-xl justify-end"
      ref={ref}
    >
      <div className="flex flex-row p-4 justify-between rounded-full  bg-[#FFCFC2]  ">
        <input
          type="text"
          placeholder="Type to Search AI Tools ...."
          className=" text-base focus-none outline-none border-none bg-[#FFCFC2] w-full text-[#7D4E35] placeholder-[#7D4E35]"
          value={searchInput.toolname}
          onChange={(e) => setSearchInput(e.target.value)}
          onKeyDown={handleKeyPress}
        />
        <Link to={`/searchToolPage/?searchToolName=${searchInput}`}>
          <img src={SearchIcon} alt="Icon" />
        </Link>
      </div>
    </div>
  );
}
