import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { url } from "../services/url";

export default function ResetPassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPage, setShowPage] = useState(true);
  const [token, setToken] = useState("");
  const handleNavigate = useNavigate();
  const prevRoute = useLocation();

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (token) {
      if (confirmPassword === password) {
        try {
          setIsLoading(true);
          const res = await axios.post(`${url}/auth/resetPassword`, {
            token,
            password,
          });
          setIsLoading(false);

          if (res.status === 200) {
            toast.success("Password resetted successfully");
            handleNavigate("/", {
              state: {
                prevRoute,
              },
            });
          }
        } catch (error) {
          setIsLoading(false);
          toast.error(error?.response?.data?.message || "Something went wrong");
        }
      } else {
        toast.error("Password do not match");
      }
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    const token = searchParams.get("token");

    if (token) {
      setToken(token);
    } else {
      setShowPage(false);
    }
  }, []);

  if (!showPage) {
    return (
      <section className=" min-h-screen flex items-center justify-center px-4">
        <div className="max-w-md mx-auto submitbg  p-8 rounded-lg shadow-lg">
          <h1 className="text-2xl font-semibold text-[#7D4E35] mb-4">
            Oops! Access Denied
          </h1>
          <p className="text-[#802C00]">
            Please make a forgot password request to access this page.
          </p>
        </div>
      </section>
    );
  }

  return (
    <section className="">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div className="w-full p-6 rounded-lg shadow dark:border md:mt-0 sm:max-w-md submitbg sm:p-8">
          <h2 className="mb-1 text-xl font-bold leading-tight tracking-tight text-[#7D4E35] md:text-2xl">
            Change Password
          </h2>
          <form
            className="mt-4 space-y-4 lg:mt-5 md:space-y-5"
            onSubmit={handleSubmit}
          >
            <div>
              <label
                htmlFor="password"
                className="block mb-2 text-sm font-medium text-[#802C00]"
              >
                New Password
              </label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  id="password"
                  placeholder="••••••••"
                  className="text-[#8B433A] sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  required
                  value={password}
                  onChange={handlePasswordChange}
                />
                <button
                  type="button"
                  onClick={handleTogglePasswordVisibility}
                  className="absolute top-1/2 right-4 transform -translate-y-1/2"
                >
                  {showPassword ? (
                    <FaEyeSlash color="black" />
                  ) : (
                    <FaEye color="black" />
                  )}
                </button>
              </div>
            </div>
            <div>
              <label
                htmlFor="confirm-password"
                className="block mb-2 text-sm font-medium  text-[#802C00]"
              >
                Confirm password
              </label>
              <div className="relative">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirm-password"
                  id="confirm-password"
                  placeholder="••••••••"
                  className="text-[#8B433A] sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  required
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                />
                <button
                  type="button"
                  onClick={handleToggleConfirmPasswordVisibility}
                  className="absolute top-1/2 right-4 transform -translate-y-1/2 "
                >
                  {showConfirmPassword ? (
                    <FaEyeSlash color="black" />
                  ) : (
                    <FaEye color="black" />
                  )}
                </button>
              </div>
            </div>

            <button
              type="submit"
              className={`bg-btn-gradient w-full text-white bg-[#FF8054] font-medium rounded-md text-sm px-5 py-2.5 text-center  ${
                isLoading ? "cursor-not-allowed" : ""
              }`}
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "Reset Password"}
            </button>
          </form>
        </div>
      </div>
    </section>
  );
}
