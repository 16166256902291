import Container from "../Container";
import logo from "../../assets/svgs/tryRabbitIcon.svg";
import fbIcon from "../../assets/svgs/Facebook.svg";
import igIcon from "../../assets/svgs/Instagram.svg";
import linkedInIcon from "../../assets/svgs/LinkedIn.svg";
import twitterIcon from "../../assets/svgs/Twitter.svg";
import { useNavigate } from "react-router-dom";

export default function Footer() {
  const handleNavigate = useNavigate();
  return (
    <div className="bottom-0 w-full border-t-4 border-[#5E321ABF] mb-[4rem] md:mb-5">
      <Container>
        <div className="bg-[#FFD4BC] flex flex-col md:flex-row justify-between rounded-b-[20px] md:rounded-b-[100px] md:pb-10">
          <div className="md:ml-10 pt-10 md:pb-10">
            <div
              className="flex flex-col md:flex-row mx-auto gap-2 justify-center align-middle cursor-pointer"
              onClick={() => handleNavigate("/")}
            >
              <img
                className="w-[80px] mx-auto"
                src={logo}
                alt="tryrabbittlogo"
              />
              <p className="text-[#7D4E35] text-3xl font-bold md:mt-10">
                Rabbitt
              </p>
            </div>
            <div className="hidden md:flex md:flex-col md:ml-4 mt-10">
              <h1 className="text-xl text-left text-[#7D4E35]">
                Hop into AI World:
              </h1>
              <p className="text-sm text-left text-[#7D4E35BF] mt-1">
                Your All-in-One Toolkit
              </p>
            </div>
            <div className="mt-1 mx-auto md:hidden ">
              <h1 className="text-sm text-[#7D4E35BF] text-center">
                Hop into AI World:Your All-in-One Toolkit
              </h1>
            </div>
          </div>
          <div className="hidden md:flex flex-col py-10 ml-10 md:ml-0 mr-10 text-[rgb(139,67,58)] text-lg">
            <div className="flex flex-col">
              <div>
                <hr className="border-t-2 border-[#8B433A] ml-5 w-1/2 md:w-full" />
              </div>
              <div className="flex flex-row align-top gap-10">
                <div>
                  <p className="rounded-full border border-[#8B433A] px-2 py-1">
                    Year Founded
                  </p>
                </div>
                <div className="mt-10">
                  <p className="text-5xl font-bold text-left">2023</p>
                </div>
              </div>
            </div>
            <div className="flex flex-col mt-5">
              <div>
                <hr className="border-t-2 border-[#8B433A] w-1/2 md:w-full ml-5" />
              </div>
              <div className="flex flex-row align-top gap-10">
                <div>
                  <p className="rounded-full border border-[#8B433A] px-2 py-1">
                    Location
                  </p>
                </div>
                <div className="mt-10">
                  <p className="text-5xl font-bold text-left">Delhi, India</p>
                </div>
              </div>
            </div>
          </div>
          <div className="md:hidden">
            <div className="w-full mx-auto">
              <hr className="w-10/12 mx-auto border-t-2 border-[#5E321ABF] mt-10" />
              <div className="flex flex-col md:flex-row text-[#7D4E35] text-xs sm:text-xl md:text-2xl mt-0 md:mt-2 md:justify-between py-5">
                <div className="mt-6 md:mt-0">
                  <p className="font-normal">
                    Copyright ©2023 Rabbitt. All Rights Reserved.
                  </p>
                </div>
                <p className="text-7D4E35] text-sm mt-9 normal">Follow Us</p>
                <div className="flex flex-row gap-10 mx-auto mt-4 md:mx-0 md:mt-0">
                  <img src={fbIcon} alt="fbIcon" />
                  <img src={igIcon} alt="igIcon" />
                  <img src={linkedInIcon} alt="linkedIn" />
                  <img src={twitterIcon} alt="twitter" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden md:flex">
          <div className="w-11/12 mx-auto">
            <hr className="border-t-2 border-[#5E321ABF] mt-10" />
            <div className="flex flex-col-reverse md:flex-row text-[#7D4E35] text-sm sm:text-xl md:text-2xl mt-2 justify-between py-5">
              <div className="mt-5 md:mt-0">
                <p className="font-normal">
                  Copyright ©2024 Rabbitt. All Rights Reserved.
                </p>
              </div>
              <div className="flex flex-row gap-10 mx-auto md:mx-0">
                <img src={fbIcon} alt="fbIcon" />
                <img src={igIcon} alt="igIcon" />
                <img src={linkedInIcon} alt="linkedIn" />
                <img src={twitterIcon} alt="twitter" />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
