/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  allProducts,
  getProductsByCategory,
} from "../services/products.service";
import Container from "../components/Container";
import CategoryDropDown from "../components/Home/CategoryDropDown";
import ToolCard from "../components/Home/ToolCard";
import Loading from "../components/Loading";
import Pagination from "../components/Pagination";
import Slogan from "../components/Slogan";
import RankingToolCard from "../components/Ranking/RankingToolCard";
import { useNavigate, useParams } from "react-router-dom";
import BreadCrumbs from "../components/BreadCrumbs";

export default function RankingMobilePage() {
  const [productsData, setProductsData] = useState([]);
  const [ selectedCategory , setSelectedCategory] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
 

  const itemsPerPage = 6;
  const [currentPage, setCurrentPage] = useState(1);
  const totalItems = productsData.length;
  const handleNavigate = useNavigate();
  const {slug} = useParams();

  

  

  useEffect(() => {
      getToolsByCategoryMobile();
  }, []);

 

  const getToolsByCategoryMobile = async () => {
    try {
      setIsLoading(true);
      const { data } = await getProductsByCategory(slug);
      const productData = data?.data;
      setProductsData(productData[slug]);
      setSelectedCategory(slug);
      setCurrentPage(1);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, totalItems);

  return (
    <div>
      <div className="mt-[5rem] pb-[5rem]">
        <Container>
          <BreadCrumbs category={"Ranking"} toolName={selectedCategory} />

          <p className="text-4xl md:text-7xl text-[#7D4E35] text-left">
            Trending Tools
          </p>
          <p className="text-lg md:text-3xl text-[#7D4E35BF] text-left pt-5">
            Unveiling the Traffic Titans in Each Category
          </p>
          {isLoading ? (
            <Loading isLoading={isLoading} className={"my-20"} />
          ) : (
            <div>
              <div className="mt-10 flex flex-row gap-5">
                {isLoading ? (
                  <Loading isLoading={isLoading} className={"my-20"} />
                ) : (
                  <div className="md:basis-8/12">
                    <div className="md:hidden flex flex-col gap-5">
                      {Array.isArray(productsData) &&
                        productsData
                          .slice(startIndex, endIndex)
                          .map((element, index) => (
                            <RankingToolCard
                              SelectedCategory={selectedCategory}
                              element={element}
                              index={startIndex + index}
                            />
                          ))}
                      <Pagination
                        totalItems={totalItems}
                        itemsPerPage={itemsPerPage}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                        className={"mt-auto"}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </Container>
      </div>
    </div>
  );
}
