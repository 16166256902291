/* eslint-disable react-hooks/rules-of-hooks */
import CarouselItems from "./Carouseltems";
import leftarrow from "../../assets/svgs/leftarrow.svg";
import rightarrow from "../../assets/svgs/rightarrow.svg";
import cube from "../../assets/svgs/CarouselCube.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useRef } from "react";

export default function CarousalToolCard({ toolData ,selectedCategory , currentTool}) {
  if (!Array.isArray(toolData)) {
    return null;
  }
  const sliderRef = useRef(null);
  const nextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };
  const prevSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 8,
          infinite: true,
        },
      },
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const filteredToolData = toolData.filter(tool => tool.slug !== currentTool);

  return (
    <>
      <Slider {...settings} ref={sliderRef}>
        {filteredToolData.map((tool, index) => (
          <div key={index} className="p-4 hover:scale-105 transform transition-transform duration-300">
            <div className="flex flex-row">
              <CarouselItems toolData={tool} selectedCategory={selectedCategory} />
            </div>
          </div>
        ))}
      </Slider>

      <div className="flex flex-row gap-5 justify-center mt-10">
        <img
          src={leftarrow}
          alt={leftarrow}
          className="cursor-pointer"
          onClick={prevSlide}
        />
        <img src={cube} alt={cube} />
        <img
          src={rightarrow}
          alt={rightarrow}
          className="cursor-pointer"
          onClick={nextSlide}
        />
      </div>
    </>
  );
}
