import { initializeApp } from "firebase/app";
import { GoogleAuthProvider } from "firebase/auth";
import { getAuth } from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyDzh0oMhbPp60cU3Z2tW3xwzfVQrO1sQu4",
  authDomain: "tryrabbit-d0108.firebaseapp.com",
  projectId: "tryrabbit-d0108",
  storageBucket: "tryrabbit-d0108.appspot.com",
  messagingSenderId: "1025168817456",
  appId: "1:1025168817456:web:13a3092a73d5c5d8079cf5",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
