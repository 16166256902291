import React from "react";
import { useState } from "react";
import Container from "../components/Container";
import { toast } from "react-hot-toast";
import PasswordButton from "../components/login/passwordButton";
import axios from "axios";
import { url } from "../services/url";

export default function ForgotPassword() {
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState({
    email: "",
  });

  const handleChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputs.email) {
      toast.error("Please fill the required field");
    } else {
      try {
        setIsLoading(true);
        const { data } = await axios.post(`${url}/auth/forgotPassword`, {
          email: inputs.email,
        });

        setIsLoading(false);
        toast.success(data.message || "Reset Password Link Sent Successfully");
      } catch (error) {
        setIsLoading(false);
        toast.error(error?.response?.data?.message || "Something went wrong");
      }
    }
  };

  return (
    <>
      <Container className={"mt-20 mb-20"}>
        <div className="flex flex-col gap-3 md:w-6/12 mx-auto">
          <p className="text-4xl text-center text-[#8B433A] font-semibold">
            Forgot Password?
          </p>
          <p className="text-xl text-center text-[#7D4E35BF]">
            Don't worry we are here to help you
          </p>
          <label className="text-[#7D4E35BF] text-lg text-left">
            Please Enter Your Email:
          </label>
          <input
            type="email"
            className="p-4 text-[#8B433A] text-sm h-10 rounded-lg"
            placeholder="Enter your Email"
            value={inputs.email}
            name="email"
            onChange={handleChange}
          />
          <PasswordButton
            text="Submit"
            isLoading={isLoading}
            handleClick={handleSubmit}
            className="p-3 rounded-lg bg-[#FF8054] text-base md:w-6/12 xl:w-3/12 mx-auto"
          />
        </div>
      </Container>
    </>
  );
}
