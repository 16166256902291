import Container from "../components/Container";
import CardComponent from "../components/login/cardComponent";
import logo from "../assets/svgs/tryRabbitIcon.svg";
import rightBall from "../assets/pngs/rightBall.png";
import leftBall from "../assets/svgs/leftBall.svg";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { loginUser } from "../services/auth.service";
import Button from "../components/signup/Button";
import { signInWithPopup } from "firebase/auth";
import { auth, provider } from "../config/firebase.config";
import axios from "axios";
import { url } from "../services/url";
export default function Login({ setRender }) {
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });
  const handleChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };
  const handleNavigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputs.email || !inputs.password) {
      toast.error("Please fill the required fields");
    } else {
      try {
        setIsLoading(true);
        const { data } = await loginUser(inputs);
        setIsLoading(false);
        localStorage.setItem("try_rabbit_cred", JSON.stringify(data?.data));
        toast.success("Logged In Successfully");
        setRender((prev) => !prev);
        setInputs({
          email: "",
          password: "",
        });
        handleNavigate(-1);
      } catch (error) {
        setIsLoading(true);
        toast.error(error.response.data.message || "Something went wrong");
        setInputs({
          email: "",
          password: "",
        });
      }
    }
  };
  const handleGoogleSignIn = async () => {
    await signInWithPopup(auth, provider)
      .then(async ({ user }) => {
        try {
          const { data } = await axios.post(`${url}/auth/google`, {
            email_verified: user.emailVerified,
            name: user.displayName,
            email: user.email,
          });

          if (data) {
            localStorage.setItem("try_rabbit_cred", JSON.stringify(data?.data));
            handleNavigate("/");
            toast.success("Logged in with Google Successfully!");
            setRender((prev) => !prev);
          }
        } catch (error) {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => console.log(error));
  };
  return (
    <>
      <div className="pb-10">
        <div className="hidden md:flex">
          <img
            src={rightBall}
            alt="right-ball"
            className="absolute top-5 -right-[10rem] -z-1 w-4/12"
          />
          <Container>
            <div className="flex flex-row login-text px-[5rem] mt-[5rem] font-semibold">
              <div className="basis-5/12">
                <CardComponent className={"login-card py-10 mb-10"}>
                  <div className="px-10" onClick={() => handleNavigate(-1)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#2A1E17"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="#2A1E17"
                      class="w-8 h-8"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                      />
                    </svg>
                  </div>
                  <div className="my-auto w-10/12 mx-auto text-left text-lg mt-10">
                    <div className="flex flex-row gap-2">
                      <img className="w-10 h-10" src={logo} alt="logo" />
                      <p className="text-[#7D4E35] text-3xl font-bold mt-2">
                        Rabbitt
                      </p>
                    </div>
                    <p className="text-2xl my-5">Login</p>
                    <form onSubmit={handleSubmit}>
                      <div>
                        <label className="ml-2 text-base font-normal mt-5">
                          Email
                        </label>
                        <input
                          className="w-full rounded-lg h-10 p-4"
                          placeholder="Email"
                          type="email"
                          name="email"
                          value={inputs.email}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="mt-5">
                        <label className="ml-2 text-base font-normal">
                          Password
                        </label>
                        <input
                          className="w-full rounded-lg h-10 p-4"
                          placeholder="Password"
                          type="password"
                          name="password"
                          value={inputs.password}
                          onChange={handleChange}
                        />
                      </div>
                      <p
                        className="text-sm my-5 cursor-pointer"
                        onClick={() => handleNavigate("/forgotPassword")}
                      >
                        forgot password?
                      </p>
                      <Button
                        className="button-color w-full text-[#FFFFFF] text-xl font-normal py-2 rounded-lg mt-5"
                        handleType="submit"
                        isLoading={isLoading}
                        text="Log In"
                      />
                      <div class="px-6 sm:px-0 w-full  mt-4">
                        <button
                          type="button"
                          className="text-black rounded-3xl hover:bg-gray-200 w-full border-gray-300 border  font-medium bg-white px-5 py-2 text-center inline-flex items-center justify-center dark:focus:ring-[#4285F4]/55 mr-2 mb-2 text-sm"
                          onClick={handleGoogleSignIn}
                        >
                          <svg
                            class="mr-2 -ml-1 w-8 h-8"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="21"
                            viewBox="0 0 20 21"
                            fill="none"
                          >
                            <g clip-path="url(#clip0_63_344)">
                              <path
                                d="M19.4999 10.7201C19.4999 9.90579 19.4352 9.31158 19.2952 8.69537H10.1937V12.3706H15.5361C15.4284 13.284 14.8468 14.6594 13.5543 15.5837L13.5361 15.7068L16.4139 17.9843L16.6132 18.0046C18.4443 16.277 19.4999 13.7351 19.4999 10.7201Z"
                                fill="#4285F4"
                              />
                              <path
                                d="M10.1938 20.4034C12.8111 20.4034 15.0084 19.5231 16.6133 18.0046L13.5543 15.5837C12.7357 16.1669 11.6371 16.5741 10.1938 16.5741C7.63032 16.5741 5.4546 14.8465 4.67902 12.4587L4.56534 12.4685L1.57304 14.8344L1.53391 14.9455C3.128 18.1806 6.40238 20.4034 10.1938 20.4034Z"
                                fill="#34A853"
                              />
                              <path
                                d="M4.67897 12.4587C4.47433 11.8425 4.3559 11.1822 4.3559 10.5C4.3559 9.81774 4.47433 9.15753 4.66821 8.54133L4.66279 8.41009L1.633 6.00626L1.53387 6.05443C0.876866 7.39691 0.499878 8.90446 0.499878 10.5C0.499878 12.0956 0.876866 13.603 1.53387 14.9455L4.67897 12.4587Z"
                                fill="#FBBC05"
                              />
                              <path
                                d="M10.1938 4.42586C12.0141 4.42586 13.2419 5.22914 13.9421 5.90042L16.6779 3.17145C14.9977 1.5759 12.8111 0.596558 10.1938 0.596558C6.40239 0.596558 3.128 2.81931 1.53391 6.05441L4.66825 8.54131C5.45461 6.15349 7.63033 4.42586 10.1938 4.42586Z"
                                fill="#EB4335"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_63_344">
                                <rect
                                  x="0.5"
                                  y="0.568176"
                                  width="19"
                                  height="19.8636"
                                  rx="6.05528"
                                  fill="white"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                          Sign in with Google<div></div>
                        </button>
                      </div>
                    </form>
                    <p className="mt-5 text-center">Or</p>
                    <p className="text-sm mt-5 text-center text-[#2A1E17] font-normal cursor-pointer">
                      Don't have an account?
                      <span
                        className="font-semibold"
                        onClick={() => handleNavigate("/signup")}
                      >
                        Register for free
                      </span>
                    </p>
                  </div>
                </CardComponent>
              </div>
              <div className="basis-7/12">
                <div className="ml-[5rem] w-8/12 mt-[10rem]">
                  <div className="flex flex-row gap-2">
                    <img className="w-10 h-10" src={logo} alt="logo" />
                    <p className="text-[#7D4E35] text-3xl font-bold mt-2">
                      Rabbitt
                    </p>
                  </div>
                  <div className="mt-10">
                    <h1 className="text-2xl text-left text-[#7D4E35]">
                      Enchanting AI Magic: Embrace Surprises!
                    </h1>
                    {/* <p className="text-xl text-left text-[#7D4E35BF] mt-5">
                      Lorem Epsum Lorem:
                    </p>
                    <p className="text-xl text-left text-[#7D4E35BF]">
                      Lorem Epsum Lorem Lorem Epsum Lorem{" "} */}
                    {/* </p> */}
                  </div>
                </div>
              </div>
            </div>
          </Container>
          <img
            src={leftBall}
            alt="left-ball"
            className="absolute -bottom-80 -left-[10rem] -z-1 w-4/12 pb-[10rem]"
          />
        </div>
        <div className="md:hidden mt-10">
          <div className="w-11/12 mx-auto rounded-xl bg-[#FFCFC2] overflow-x-hidden mb-10">
            <img
              src={rightBall}
              alt="right-ball"
              className="ml-[16rem] w-7/12 -mt-8"
            />
            <div className="-mt-[100px] mx-auto w-8/12 pb-10">
              <div className="flex flex-row gap-1">
                <img className="w-10 h-10" src={logo} alt="logo" />
                <p className="text-[#7D4E35] text-3xl font-bold mt-2">
                  Rabbitt
                </p>
              </div>
              <h1 className="text-2xl text-left text-[#7D4E35] mt-2">
                Hop into AI World:
              </h1>
              <p className="text-xl text-left text-[#7D4E35BF]">
                Your All-in-One Toolkit
              </p>
            </div>
          </div>
          <Container>
            <CardComponent className={"login-card-mobile py-10 px-10 mb-10"}>
              <div className="my-auto w-full mx-auto text-left text-lg mt-10 text-[#802C00]">
                <p className="text-2xl my-5">Login</p>
                <form onSubmit={handleSubmit}>
                  <div>
                    <label className="text-base font-normal mt-5">Email</label>
                    <input
                      className="w-full rounded-lg h-10 p-4"
                      placeholder="Email"
                      type="email"
                      name="email"
                      value={inputs.email}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mt-5">
                    <label className="text-base font-normal">Password</label>
                    <input
                      className="w-full rounded-lg h-10 p-4"
                      placeholder="Password"
                      type="password"
                      name="password"
                      value={inputs.password}
                      onChange={handleChange}
                    />
                  </div>

                  <p
                    className="text-sm my-5 cursor-pointer"
                    onClick={() => handleNavigate("/forgotPassword")}
                  >
                    forgot password?
                  </p>

                  <Button
                    className="bg-[#F25019] w-full text-[#FFFFFF] text-xl font-normal py-2 rounded-lg mt-5"
                    handleType="submit"
                    isLoading={isLoading}
                    text="Log In"
                  />
                </form>
              </div>
            </CardComponent>
            <div class="px-6 sm:px-0 w-full  mt-4">
              <button
                type="button"
                className="text-black rounded-3xl hover:bg-gray-200 w-full border-gray-300 border  font-medium bg-white px-5 py-4 text-center inline-flex items-center justify-center dark:focus:ring-[#4285F4]/55 mr-2 mb-2 text-lg"
                onClick={handleGoogleSignIn}
              >
                <svg
                  class="mr-2 -ml-1 w-8 h-8"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                >
                  <g clip-path="url(#clip0_63_344)">
                    <path
                      d="M19.4999 10.7201C19.4999 9.90579 19.4352 9.31158 19.2952 8.69537H10.1937V12.3706H15.5361C15.4284 13.284 14.8468 14.6594 13.5543 15.5837L13.5361 15.7068L16.4139 17.9843L16.6132 18.0046C18.4443 16.277 19.4999 13.7351 19.4999 10.7201Z"
                      fill="#4285F4"
                    />
                    <path
                      d="M10.1938 20.4034C12.8111 20.4034 15.0084 19.5231 16.6133 18.0046L13.5543 15.5837C12.7357 16.1669 11.6371 16.5741 10.1938 16.5741C7.63032 16.5741 5.4546 14.8465 4.67902 12.4587L4.56534 12.4685L1.57304 14.8344L1.53391 14.9455C3.128 18.1806 6.40238 20.4034 10.1938 20.4034Z"
                      fill="#34A853"
                    />
                    <path
                      d="M4.67897 12.4587C4.47433 11.8425 4.3559 11.1822 4.3559 10.5C4.3559 9.81774 4.47433 9.15753 4.66821 8.54133L4.66279 8.41009L1.633 6.00626L1.53387 6.05443C0.876866 7.39691 0.499878 8.90446 0.499878 10.5C0.499878 12.0956 0.876866 13.603 1.53387 14.9455L4.67897 12.4587Z"
                      fill="#FBBC05"
                    />
                    <path
                      d="M10.1938 4.42586C12.0141 4.42586 13.2419 5.22914 13.9421 5.90042L16.6779 3.17145C14.9977 1.5759 12.8111 0.596558 10.1938 0.596558C6.40239 0.596558 3.128 2.81931 1.53391 6.05441L4.66825 8.54131C5.45461 6.15349 7.63033 4.42586 10.1938 4.42586Z"
                      fill="#EB4335"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_63_344">
                      <rect
                        x="0.5"
                        y="0.568176"
                        width="19"
                        height="19.8636"
                        rx="6.05528"
                        fill="white"
                      />
                    </clipPath>
                  </defs>
                </svg>
                Sign in with Google<div></div>
              </button>
            </div>
            <p className="text-[#802C00] text-sm mt-5 text-center">or</p>
            <p className="text-sm mt-5 text-center text-[#2A1E17] font-normal cursor-pointer">
              Don't have an account?
              <span
                className="font-semibold"
                onClick={() => handleNavigate("/signup")}
              >
                Register for free
              </span>
            </p>
          </Container>
        </div>
      </div>
    </>
  );
}
